import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD } from '../auth/graphql/Mutations';

const { required } = formValidatorRules;
const ChangePassword = (props) => {
  const { show, setShowModal } = props;
  const [form] = Form.useForm();
  const [changePassword] = useMutation(RESET_PASSWORD, {
    onError() {}
  });
  const [loading, setLoading] = useState(false);
  const handleAdd = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    try {
      changePassword({
        variables: { ...values }
      });
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };
  return (
    <Modal
      title="Change Password"
      visible={show}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog"
      okText="Save"
      onCancel={handleCancel}
    >
      <Form
        form={form}
        name="change-password"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="gx-login-form gx-form-row0"
      >
        <Form.Item name="oldPassword" rules={[required]}>
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Old password"
          />
        </Form.Item>
        <Form.Item name="newPassword" rules={[required]}>
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="retypePassword"
          rules={[
            required,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value !== getFieldValue('newPassword')) {
                  return Promise.reject(new Error('Passwords do not match'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Retype password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
