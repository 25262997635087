import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { formValidatorRules } from '../../common/utils';
import { CREATE_UI_LABELS, UPDATE_UI_LABELS } from './graphql/Mutations';

const { required } = formValidatorRules;

const AddEditLabels = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    refetchUiLabelsWithInitialValues,
    refetchUiLabels
  } = props;
  const [form] = Form.useForm();
  const [createUserMutate, { loading: createUserLoading }] = useMutation(
    CREATE_UI_LABELS,
    {
      onError() {}
    }
  );

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_UI_LABELS,
    {
      onError() {}
    }
  );

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  const onUserSubmitFinish = async (formValues) => {
    const newFormValues = {
      ...formValues
    };

    const variables = isUpdate
      ? { uiLabel: newFormValues, id: userData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        const response = await updateUserMutate({
          variables: { ...variables }
        });
        if (response?.data?.updateUiLabel) {
          setUserData();
          form.resetFields();
          setShowModal(false);
          refetchUiLabels();
        }
        return response;
      }
      const response = await createUserMutate({
        variables: { uiLabel: { ...variables } }
      });
      if (response?.data?.createUiLabel) {
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUiLabelsWithInitialValues();
      }
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  return (
    <Modal
      title={isUpdate ? 'Edit UI Label' : 'Add UI Label'}
      visible={showModal}
      confirmLoading={createUserLoading || updateUserLoading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        initialValues={userData || {}}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        {isUpdate ? (
          <>
            <Form.Item rules={[required]} name="value" label="Value">
              <Input allowClear name="Value" />
            </Form.Item>
            <Form.Item
              normalize={(val) => val?.toUpperCase()}
              rules={[required]}
              name="groupKey"
              label="Group Key"
            >
              <Input allowClear name="GroupKey" />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              rules={[required]}
              name="key"
              label="Key"
              normalize={(val) => val?.toUpperCase()}
            >
              <Input allowClear name="Key" />
            </Form.Item>
            <Form.Item rules={[required]} name="enValue" label="English Value">
              <Input allowClear name="En Value" />
            </Form.Item>
            <Form.Item rules={[required]} name="esValue" label="Spanish value">
              <Input allowClear name="Es Value" />
            </Form.Item>
            <Form.Item
              normalize={(val) => val?.toUpperCase()}
              rules={[required]}
              name="groupKey"
              label="Group Key"
            >
              <Input allowClear name="Group Key" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddEditLabels;
