import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location = ROUTES.LOGIN;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Spin className="spin" size="large" />;
};

export default Logout;
