import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_UI_LABELS = gql`
  query adminUiLabels($filters: UiLabelListInput) {
    adminUiLabels(filters: $filters) {
      total
      data {
        id
        key
        value
        groupKey
        language
        created_at
        updated_at
      }
    }
  }
`;
