import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import AddEditLabels from '../AddEditLabels';
import { DELETE_UI_LABELS } from '../graphql/Mutations';
import { GET_UI_LABELS } from '../graphql/Queries';

const UiLabelsList = () => {
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    sortBy: 'createdAt_DESC'
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [sortedInfo, setSortedInfo] = useState({});

  const [userFilter, setUserFilter] = useState(initialUserFilter);

  const [deleteUiMutate, { loading: deleteUiLoading }] = useMutation(
    DELETE_UI_LABELS,
    {
      onError() {}
    }
  );

  const [fetchUiLabels, { loading, data, refetch }] = useLazyQuery(
    GET_UI_LABELS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.adminUiLabels?.total
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchUiLabels({ variables: { filters: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setUserFilter({
      skip: 0,
      limit: 10,
      sortBy: 'createdAt_DESC'
    });
    setPaginationProp(initialPaginationValue);
    fetchUiLabels({ variables: { filters: { ...initialUserFilter } } });
  };

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setUserFilter({ ...userFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchUiLabels({
        variables: {
          filters: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        sortBy: 'createdAt_DESC'
      });
      fetchUiLabels({
        variables: {
          filters: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const handleUIDelete = async (e, editUIData) => {
    e?.preventDefault();
    const response = await deleteUiMutate({
      variables: { where: { key: editUIData.key } }
    });
    if (response?.data?.deleteUiLabel) {
      refetch();
    }
  };

  const renderActionButtons = (editUIData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            setUserData(editUIData);
            handleAddEditUser();
          }}
        >
          Edit
        </Button>
        <Button
          id="user-table-status-btn"
          className="b-0"
          onClick={(e) => handleUIDelete(e, editUIData)}
        >
          Delete
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'Group Key',
      dataIndex: 'groupKey',
      key: 'groupKey'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Popover placement="bottom" content={renderActionButtons(record)}>
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, search: value });
    fetchUiLabels({
      variables: { filters: { ...userFilter, skip: 0, search: value } }
    });
  };

  // eslint-disable-next-line no-console
  console.log('userFilter, paginationProps => ', userFilter, paginationProp);

  return (
    <>
      {showModal && (
        <AddEditLabels
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUiLabelsWithInitialValues={refetchUserDetails}
          refetchUiLabels={refetch}
        />
      )}
      <Portal portalId="uiLabel-search">
        <div className="mb-10">
          <SearchComponent
            id="search-container-id"
            name="UI Labels"
            getData={onSearchChange}
          />
        </div>
      </Portal>
      <Portal portalId="uiLabel-btn">
        <Button type="primary" onClick={handleAddEditUser}>
          Add UI Label
        </Button>
      </Portal>
      <TableComponent
        loadingData={loading || deleteUiLoading}
        columns={columns}
        data={data?.adminUiLabels?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
      />
    </>
  );
};

export default UiLabelsList;
