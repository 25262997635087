import * as Sentry from '@sentry/react';
import React, { useContext, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import history from './historyData';
import ChangePassword from './modules/auth/ChangePassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ResetPassword from './modules/auth/ResetPassword';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { initializeAuth, defaultPageSize } = useContext(AppContext);

  useEffect(() => {
    initializeAuth();
    defaultPageSize();
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <Route exact path={ROUTES.LOGOUT} component={Logout} />
          <PublicRoute exact path={ROUTES.RESET} component={ResetPassword} />
          <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
          <PublicRoute exact path={ROUTES.CHANGE} component={ChangePassword} />
          <PrivateRoute path={ROUTES.MAIN} component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
