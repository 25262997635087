import { Card } from 'antd';
import React from 'react';
import Portal from '../../components/Portal';
import UiLabelsList from './components/UiLabelsList';

const UiLabels = () => {
  return (
    <Card>
      <Portal portalId="header-title">
        <h1 className="m-0">UI Labels</h1>
      </Portal>
      <div className="d-flex align-center justify-between">
        <div id="uiLabel-search" />
        <div id="uiLabel-btn" />
      </div>
      <hr />
      <UiLabelsList />
    </Card>
  );
};

export default UiLabels;
