import { gql } from '@apollo/client';

export const CREATE_UI_LABELS = gql`
  mutation createUiLabel($uiLabel: UiLabelInput!) {
    createUiLabel(uiLabel: $uiLabel) {
      data {
        id
        key
        value
        groupKey
        language
        created_at
        updated_at
      }
      message
    }
  }
`;

export const UPDATE_UI_LABELS = gql`
  mutation updateUiLabel($id: ID!, $uiLabel: UiLabelUpdateInput!) {
    updateUiLabel(id: $id, uiLabel: $uiLabel) {
      data {
        id
        key
        value
        groupKey
        language
        created_at
        updated_at
      }
      message
    }
  }
`;

export const DELETE_UI_LABELS = gql`
  mutation deleteUiLabel($where: DeleteUiLabelInput!) {
    deleteUiLabel(where: $where) {
      status
      message
    }
  }
`;
