import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation adminLogin($data: loginData!) {
    login(data: $data) {
      token
      user {
        id
        first_name
        last_name
        roles
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotUserPassword($email: String) {
    forgotUserPassword(where: { email: $email }) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation isValidToken($resetToken: String) {
    isValidToken(data: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;
